/*

    1. BASE
    reset
    base

    2. Element
    Header
    Footer
    Graphics
    Row
    Button
    Banners
    Cards
    HeroIntro
    Icon
    Form
    Alerts
    Tooltip
    Loader
    Modal
    Stepper
    CausalCard
    DropZone
    Violentometro
    StatusBar
    Chatbox
    Hospital
    Tags
    Rating

    3. Layout
    Auth
    Donate

    4. responsive
    Large
    Medium
    Small

*/
/* ------------------------------------------------------------
     VARIABLES
------------------------------------------------------------ */
@font-face {
  font-family: "CircularStd-Black";
  src: url("../public/assets/fonts/CircularStd-Black.otf") format("opentype");
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: "CircularStd-Bold";
  src: url("../public/assets/fonts/CircularStd-Bold.otf") format("opentype");
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: "CircularStd-Medium";
  src: url("../public/assets/fonts/CircularStd-Medium.otf") format("opentype");
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: "CircularStd-Book";
  src: url("../public/assets/fonts/CircularStd-Book.otf") format("opentype");
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: "CircularStd-Light";
  src: url("../public/assets/fonts/CircularStd-Light.otf") format("opentype");
  font-weight: 300;
  font-display: swap;
}
/* ------------------------------------------------------------
     MIXINS
------------------------------------------------------------ */
/* ------------------------------------------------------------
     RESET
------------------------------------------------------------ */
html,
body {
  -webkit-text-size-adjust: none;
  /* For iphone Landscape */
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  /* For font looks antialiased */
  -moz-osx-font-smoothing: grayscale;
  /* Moz antialiased */
  text-rendering: optimizeLegibility;
  /* optimezy fonts */
  font-size: 16px;
  color: #1f1f1f;
  font-family: "CircularStd-Book", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  line-height: 25px;
  height: 100%;
}

/* headings */
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
blockquote,
h5,
.h5,
h6,
.h6 {
  letter-spacing: -0.5px;
  font-weight: bold;
  font-family: "CircularStd-Black", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

h1,
.h1 {
  font-size: 35px;
  line-height: 40px;
}

h2,
.h2 {
  font-size: 32px;
  line-height: 40px;
}

h3,
.h3 {
  font-size: 28px;
  line-height: 35px;
}

h4,
.h4 {
  font-size: 24px;
  line-height: 30px;
}

h5,
.h5 {
  font-size: 18px;
  line-height: 25px;
}

h6,
.h6 {
  font-size: 16px;
  line-height: 25px;
}

/* outline */
* {
  -ms-touch-action: manipulation;
  -webkit-touch-action: manipulation;
  touch-action: manipulation;
}
*:focus {
  outline: none !important;
}

/* Placeholder */
::-webkit-input-placeholder {
  color: #CDCDCD !important;
}

::-moz-placeholder {
  color: #CDCDCD !important;
} /* firefox 19+ */
:-ms-input-placeholder {
  color: #CDCDCD !important;
} /* ie */
:-moz-placeholder {
  color: #CDCDCD !important;
}

/* Selection */
::-moz-selection {
  color: #1F7164;
  background: #E0FBE1;
  opacity: 1 !important;
}
::selection {
  color: #1F7164;
  background: #E0FBE1;
  opacity: 1 !important;
}

::-moz-selection {
  color: #1F7164;
  background: #E0FBE1;
  opacity: 1 !important;
}

/* mx auto */
.mx-auto {
  margin: 0 auto;
}

.col-reset {
  padding: 0;
}

/* Hidden */
.hidden {
  display: none !important;
}

/* Address */
address {
  font-style: initial;
}

/* padding */
.pl-0 {
  padding-left: 0 !important;
}

/* Margin */
.mt8 {
  margin-top: 8px;
}

.mt16 {
  margin-top: 16px;
}

.mt24 {
  margin-top: 24px;
}

.mt32 {
  margin-top: 32px;
}

.mt40 {
  margin-top: 40px;
}

.mb8 {
  margin-bottom: 8px;
}

.mb16 {
  margin-bottom: 16px;
}

.mb24 {
  margin-bottom: 24px;
}

.mb32 {
  margin-bottom: 32px;
}

.mb40 {
  margin-bottom: 40px;
}

/* text color */
.text-base,
.text-base * {
  color: #1f1f1f;
}

.text-gray600,
.text-gray600 * {
  color: #8A8A8A;
}

.text-gray700,
.text-gray700 * {
  color: #707070;
}

.text-gray800,
.text-gray800 * {
  color: #545454;
}

.text-wine400,
.text-wine400 * {
  color: #8D3D64;
}

.text-pink400,
.text-pink400 * {
  color: #C47A90;
}

.text-purple400,
.text-purple400 * {
  color: #845DB6 !important;
}

.text-green300,
.text-green300 * {
  color: #6BBBAE;
}

.text-green350,
.text-green350 * {
  color: #529770;
}

.text-green400,
.text-green400 * {
  color: #1F7164;
}

.text-green700,
.text-green700 * {
  color: #16615C;
}

.text-green900,
.text-green900 * {
  color: #144B59;
}

/* text size */
.text-64,
.text-64 * {
  font-size: 64px;
  line-height: 70px;
}

.text-52,
.text-52 * {
  font-size: 52px;
  line-height: 60px;
}

.text-40,
.text-40 * {
  font-size: 40px;
  line-height: 45px;
}

.text-32,
.text-32 * {
  font-size: 32px;
  line-height: 40px;
}

.text-24,
.text-24 * {
  font-size: 24px;
  line-height: 30px;
}

.text-20,
.text-20 * {
  font-size: 20px;
  line-height: 28px;
}

.text-16,
.text-16 * {
  font-size: 16px;
  line-height: 25px;
}

.text-14,
.text-14 * {
  font-size: 14px;
  line-height: 25px;
}

/* icon */
.text-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.text-icon i {
  margin-right: 8px;
}

.text-hide {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  top: -2px;
  margin-left: 4px;
}
.text-hide * {
  margin-right: 4px;
  font-size: 8px;
  line-height: 20px;
}

/* text font */
.text-regular,
.text-regular * {
  font-family: "CircularStd-Book", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.text-titular,
.text-titular * {
  font-family: "CircularStd-Black", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.text-400,
.text-400 * {
  font-family: "CircularStd-Medium", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.text-500,
.text-500 * {
  font-family: "CircularStd-Black", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.text-700,
.text-700 * {
  font-family: "CircularStd-Black", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.text-underline,
.text-underline * {
  text-decoration: underline;
}

strong {
  font-family: "CircularStd-Black", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 300;
}

/* input number */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
  -moz-appearance: textfield; /* Firefox */
}

/* ------------------------------------------------------------
     BASE
------------------------------------------------------------ */
#root,
.app-main {
  width: 100%;
  height: 100%;
}

/* module */
.module {
  padding: 80px 0;
}

.module-bottom {
  padding-top: 0;
  padding-bottom: 80px;
}

.module-top {
  padding-top: 80px;
  padding-bottom: 0;
}

.module40 {
  padding: 40px 0;
}

.module-bottom40 {
  padding-top: 0;
  padding-bottom: 40px;
}

.module-top40 {
  padding-top: 40px;
  padding-bottom: 0;
}

/* center list */
.center-list {
  list-style: none;
  padding: 24px 0;
  margin: 0;
}
.center-list li {
  margin-bottom: 32px;
}
.center-list li:last-child {
  margin-bottom: 0;
}

/* ------------------------------------------------------------
     HEADER
------------------------------------------------------------ */
header {
  padding: 25px 0;
  width: 100%;
  background: #fff;
}

.header__col {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.header__logo {
  width: 100px;
  margin: 0;
  position: relative;
  z-index: 11;
}
.header__logo figure {
  margin: 0;
}
.header__logo img {
  width: 100%;
}

/* main nav */
.main-nav ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0;
  margin: 0;
  list-style: none;
}
.main-nav ul li {
  margin-left: 64px;
}
.main-nav ul li a {
  color: #1f1f1f;
  position: relative;
  text-decoration: none;
  font-weight: 400;
  font-family: "CircularStd-Book", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.main-nav ul li a:after {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 100%;
  height: 2px;
  border-radius: 5px;
  background: transparent;
}
.main-nav ul li a.active {
  color: #1F7164;
  font-family: "CircularStd-Medium", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.main-nav ul li a.active:after {
  background: #1F7164;
}
.main-nav ul li a:hover {
  color: #1F7164;
}

/* open nav */
.open-nav {
  width: 50px;
  height: 25px;
  position: relative;
  -webkit-transform: scale(0.7) rotate(0deg);
          transform: scale(0.7) rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  background: transparent;
  border: 0;
  display: none;
  z-index: 11;
}
.open-nav.active span {
  background: #fff;
}
.open-nav.active span:nth-child(1), .open-nav.active span:nth-child(6) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.open-nav.active span:nth-child(2), .open-nav.active span:nth-child(5) {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.open-nav.active span:nth-child(1) {
  left: 5px;
  top: 7px;
}
.open-nav.active span:nth-child(2) {
  left: calc(50% - 5px);
  top: 7px;
}
.open-nav.active span:nth-child(3) {
  left: -50%;
  opacity: 0;
}
.open-nav.active span:nth-child(4) {
  left: 100%;
  opacity: 0;
}
.open-nav.active span:nth-child(5) {
  left: 5px;
  top: 22px;
}
.open-nav.active span:nth-child(6) {
  left: calc(50% - 5px);
  top: 22px;
}
.open-nav span {
  display: block;
  position: absolute;
  height: 3px;
  width: 50%;
  background: #1F7164;
  opacity: 1;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
.open-nav span:nth-child(even) {
  left: 50%;
}
.open-nav span:nth-child(odd) {
  left: 0px;
}
.open-nav span:nth-child(1), .open-nav span:nth-child(2) {
  top: 0px;
}
.open-nav span:nth-child(3), .open-nav span:nth-child(4) {
  top: 11px;
}
.open-nav span:nth-child(5), .open-nav span:nth-child(6) {
  top: 22px;
}

/* user control */
.user-control {
  position: relative;
}
.user-control a {
  text-decoration: none;
}

.user-options {
  position: absolute;
  right: 0;
  top: 50px;
  width: 360px;
  background: #F0FFF8;
  padding: 20px;
  border: 2px solid #1F7164;
  border-radius: 12px 0 12px 12px;
  display: none;
  z-index: 10;
}

.user-options__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom: 1px solid #B1B1B1;
  padding-bottom: 25px;
  margin-bottom: 25px;
}
.user-options__top article {
  padding-left: 24px;
}
.user-options__top article h3 {
  margin-bottom: 0;
}

.user-options__bottom ul {
  list-style: none;
  padding: 0 22px;
  margin: 0;
}
.user-options__bottom ul li {
  width: 100%;
  margin-bottom: 29px;
}
.user-options__bottom ul li:last-child {
  margin-bottom: 0;
}
.user-options__bottom ul li i {
  margin-right: 43px;
}
.user-options__bottom ul li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

/* notifications */
.header__options {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.header__options .header-alert {
  margin-right: 24px;
}

.notifications {
  margin-right: 16px;
}

/* mobile options */
.main-nav__mobile {
  display: none;
}

.header-options__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 24px;
}
.header-options__top > * {
  margin: 0 16px;
}
.header-options__top a {
  width: auto !important;
}

.header__options {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.header__options .header-alert {
  margin-right: 24px;
}

/* -----

 MEDIAQUERIES 

----- */
@media (max-width: 1300px) {
  .main-nav ul li {
    margin-left: 34px;
  }
}
@media (max-width: 1200px) {
  .main-nav ul li {
    margin-left: 34px;
  }
  .header__options {
    position: relative;
  }
  .header__options .header-alert {
    position: absolute;
    top: 60px;
    right: 0;
  }
  .header__options .header-alert p {
    font-size: 14px;
  }
}
@media (min-width: 993px) {
  .user-control:hover .user-options {
    display: block;
  }
  .user-control-button {
    pointer-events: none;
  }
}
@media (max-width: 992px) {
  .header__options {
    display: none;
  }
  .open-nav {
    display: block;
  }
  .main-nav {
    opacity: 0;
    pointer-events: none;
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transition: 0.4s all ease-in-out;
    transition: 0.4s all ease-in-out;
    background: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
  }
  .main-nav ul {
    background: #1F7164;
    height: auto;
    display: block;
    padding: 130px 15px 30px 15px;
  }
  .main-nav ul li {
    width: 100%;
    margin-bottom: 32px;
    margin-left: auto;
    margin-right: auto;
    max-width: 720px;
    padding: 0 15px;
  }
  .main-nav ul li:last-child {
    margin-bottom: 0;
  }
  .main-nav ul li a {
    color: #fff;
    width: 100%;
    display: block;
  }
  .main-nav ul li a.active {
    color: #E0FBE1;
  }
  .main-nav ul li a.active:after {
    background: #E0FBE1;
  }
  .main-nav ul li a:hover {
    color: #E0FBE1;
  }
  .main-nav.active {
    opacity: 1;
    pointer-events: initial;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@media (max-width: 768px) {
  .main-nav ul li {
    max-width: 540px;
  }
}
@media (max-width: 680px) {
  .main-nav ul {
    padding-top: 120px;
  }
  .main-nav ul li {
    padding: 0 30px;
  }
}
/* ------------------------------------------------------------
     FOOTER
------------------------------------------------------------ */
footer {
  background: #6BBBAE;
  padding: 32px 0;
  width: 100%;
}
footer .container p,
footer .container a,
footer .container h1,
footer .container h2,
footer .container h3,
footer .container h4,
footer .container h5,
footer .container h6 {
  color: #1f1f1f;
}
footer .container a {
  font-weight: 400;
  text-decoration: none;
}

.footer__logo {
  width: 130px;
  display: block;
  margin-bottom: 16px;
}
.footer__logo figure {
  margin: 0;
  width: 100%;
}
.footer__logo figure img {
  width: 100%;
}

.footer__row {
  width: 100%;
  margin-bottom: 16px;
}

/* social */
.social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0;
  margin: 0;
  list-style: none;
}
.social li {
  margin-right: 24px;
}

/* contact */
.contact {
  padding: 0;
  margin: 0;
  list-style: none;
}
.contact li {
  width: 100%;
  margin-bottom: 8px;
}
.contact li:last-child {
  margin-bottom: 0;
}
.contact li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.contact li a .icon {
  margin-right: 8px;
}

/* quick links */
.quicklinks {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  width: 100%;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  list-style: none;
  padding: 0;
  margin: 0;
}
.quicklinks li {
  width: 100%;
  margin-bottom: 16px;
}
.quicklinks li:last-child {
  margin-bottom: 0;
}
.quicklinks li a {
  font-weight: bold;
}

/* -----

 MEDIAQUERIES 

----- */
@media (max-width: 992px) {
  .footer__col {
    margin-bottom: 32px;
  }
  .footer__col .quicklinks {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .footer__col .quicklinks li {
    width: 100%;
    margin-bottom: 24px;
  }
  .footer__col .quicklinks li:last-child {
    margin-bottom: 0;
  }
  .footer__quicklinks {
    display: none;
  }
}
/* ------------------------------------------------------------
     ROW
------------------------------------------------------------ */
.image-350 {
  margin: 0;
}
.image-350,
.image-350 img {
  max-width: 350px;
  width: 100%;
}

.image-270 {
  margin: 0;
}
.image-270,
.image-270 img {
  max-width: 270px;
  width: 100%;
}

.image-64 {
  margin: 0;
}
.image-64,
.image-64 img {
  max-width: 64px;
  width: 100%;
}

/* -----

MEDIAQUERIES 

----- */
@media (max-width: 992px) {
  .row-end {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
}
@media (max-width: 768px) {
  .image-270,
  .image-270 img {
    max-width: 190px;
  }
}
/* ------------------------------------------------------------
     GRAPHICS
------------------------------------------------------------ */
.row-end {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.row-middle {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

/* -----

MEDIAQUERIES 

----- */
@media (max-width: 992px) {
  .row-end {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
/* ------------------------------------------------------------
     BUTTON
------------------------------------------------------------ */
a,
button {
  color: #1F7164;
  font-family: "CircularStd-Medium", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  border: 0;
  background: transparent;
  cursor: pointer;
}
a:hover,
button:hover {
  text-decoration: none;
}
a.btn--simple,
button.btn--simple {
  font-size: 20px;
  text-decoration: none;
}
a.btn--simple:hover,
button.btn--simple:hover {
  color: #16615C;
}
a.btn--danger,
button.btn--danger {
  color: #AC0B1F;
  font-size: 20px;
  text-decoration: none;
}

.btn {
  min-height: 60px;
  border-radius: 12px;
  font-family: "CircularStd-Black", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding-left: 36px;
  padding-right: 36px;
}
.btn.btn--type1 {
  color: #fff;
  background: #1F7164;
}
.btn.btn--type1:hover {
  background: #6BBBAE;
  color: #144B59;
}
.btn.btn--type2 {
  color: #1F7164;
  background: transparent;
  border: 2px solid #1F7164;
}
.btn.btn--type2:hover {
  background: #6BBBAE;
  color: #144B59;
  border-color: #6BBBAE;
}
.btn.btn--type3 {
  color: #fff;
  background: #845DB6;
}
.btn.btn--type3:hover {
  background: #e1d5ef;
  color: #845DB6;
}
.btn.btn--type4 {
  color: #144B59;
  background: #CDF8E5;
  border: 1px solid #529770;
}
.btn.btn--type4:hover {
  background: #A0DAB3;
}
.btn.btn--type4.active {
  background: #A0DAB3;
}
.btn.btn--100 {
  width: 100%;
}
.btn.btn--180 {
  width: 180px;
}
.btn.btn--290 {
  width: 290px;
}
.btn.btn--simple {
  font-size: 20px;
  color: #1F7164;
  text-decoration: underline;
  font-family: "CircularStd-Medium", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.btn.btn--simple:hover {
  text-decoration: none;
}
.btn.btn--delete {
  color: #fff;
  background: #9D1D2A;
}
.btn.btn--delete:hover {
  background: #AC0B1F;
}
.btn.btn--code {
  border: 1px solid #E4A9BB;
  border-radius: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 138px;
  width: 322px;
  padding-left: 10px;
  padding-right: 10px;
}
.btn.btn--code:hover, .btn.btn--code.active {
  border-color: #845DB6;
  background: #E0FBE1;
}
.btn.btn--code:hover i,
.btn.btn--code:hover p, .btn.btn--code.active i,
.btn.btn--code.active p {
  color: #1F7164;
}
.btn.btn--code i {
  font-size: 30px;
}
.btn.btn--code p {
  width: 80%;
  text-align: center;
  margin: 0;
}
.btn.btn--close {
  background: #E0FBE1;
  padding: 16px;
}
.btn.btn--close span {
  font-size: 30px;
  color: #1F7164;
}
.btn.btn--chat {
  color: #fff;
  background: #1F7164;
  border-radius: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 0 24px;
  height: 77px;
}
.btn.btn--chat:hover {
  background: #16615C;
}
.btn.btn--chat i {
  margin-left: 16px;
}
.btn.btn--icon .icon {
  font-size: 32px;
  margin-right: 8px;
}
.btn[disabled] {
  background: #CDCDCD;
  color: #8A8A8A;
  border-color: #CDCDCD;
}

/* -----

MEDIAQUERIES 

----- */
/* ------------------------------------------------------------
     BANNERS
------------------------------------------------------------ */
.banner-color {
  background: #E0FBE1;
  padding: 32px;
}

/* -----

MEDIAQUERIES 

----- */
/* ------------------------------------------------------------
     CARDS
------------------------------------------------------------ */
.card {
  border-color: #D295BF;
  border-radius: 16px;
  padding: 20px;
  height: 100%;
  width: 100%;
}

.card-title {
  margin-bottom: 24px;
}

/* item card */
.item-workshop {
  border: 2px solid #845DB6;
  border-radius: 10px;
}
.item-workshop article {
  padding: 16px 20px;
  background: #9B7AC4;
}
.item-workshop .btn {
  width: 100%;
}

.item-workshop__image {
  position: relative;
  margin: 0;
  height: 154px;
}
.item-workshop__image img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center;
     object-position: center;
}
.item-workshop__image .badge {
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 1;
}

/* stock list */
.stock-list {
  list-style: none;
  padding: 8px 0;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 14px;
  line-height: 14px;
  color: #845DB6;
  margin: 0;
}
.stock-list li {
  margin: 0 2px;
}
.stock-list li .icon {
  color: #CDCDCD;
  font-size: 16px;
}
.stock-list li .icon.stock--enabled {
  color: #D295BF;
}

.item-workshop__col {
  margin-bottom: 16px;
}

.card-row-item {
  width: 100%;
  padding: 32px;
  border-radius: 16px;
  border: 1px solid #E4A9BB;
  height: 100%;
}

.card-row-item__image {
  margin: 0;
  height: 250px;
  padding: 24px 23px;
}
.card-row-item__image img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center;
     object-position: center;
}

.card-row-item__title {
  padding: 32px 0;
  width: 100%;
}

.card-row-item__text {
  padding-bottom: 32px;
  width: 100%;
}
.card-row-item__text p:last-child {
  margin-bottom: 0;
}

/* info card */
.info-card {
  border: 1px solid #91D6C4;
  border-radius: 16px;
  height: 100%;
}
.info-card.type--1 .info-card__head {
  background: #CDF8E5;
}
.info-card.type--1 .info-card__head p {
  color: #529770;
}
.info-card.type--2 {
  border-color: #F9D3D3;
}
.info-card.type--2 .info-card__head {
  background: #F9D3D3;
}
.info-card.type--2 .info-card__head p {
  color: #8D3D64;
}

.info-card__head {
  padding: 16px;
  background: #91D6C4;
  border-radius: 16px 16px 0 0;
  min-height: 108px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.info-card__body {
  padding: 32px;
}

/* -----

MEDIAQUERIES 

----- */
@media (max-width: 768px) {
  .card-row-item {
    padding: 18px;
  }
  .card-row-item__text {
    min-height: initial;
  }
  .card-row-item__title {
    padding: 16px 0;
  }
  .card-row-item__image {
    height: 200px;
  }
  .card {
    padding: 10px;
  }
}
/* ------------------------------------------------------------
     HERO-INTRO
------------------------------------------------------------ */
/* -----

MEDIAQUERIES 

----- */
@media (max-width: 992px) {
  .hero-intro__col-top {
    margin-bottom: 32px;
  }
}
/* ------------------------------------------------------------
     ICON
------------------------------------------------------------ */
.icon {
  display: inline-block;
}

.icon--instagram {
  background: url(/public/assets/images/icons/instagram.svg) center center no-repeat;
  background-size: contain;
  width: 24px;
  height: 24px;
}

.icon--twitter {
  background: url(/public/assets/images/icons/twitter.svg) center center no-repeat;
  background-size: contain;
  width: 24px;
  height: 24px;
}

.icon--phone {
  background: url(/public/assets/images/icons/phone.svg) center center no-repeat;
  background-size: contain;
  width: 18px;
  height: 18px;
}

.icon--phone-call {
  background: url(/public/assets/images/icons/phone-call.svg) center center no-repeat;
  background-size: contain;
  width: 34px;
  height: 34px;
}

.icon--email {
  background: url(/public/assets/images/icons/email.svg) center center no-repeat;
  background-size: contain;
  width: 18px;
  height: 18px;
}

.icon--upload {
  background: url(/public/assets/images/icons/upload.svg) center center no-repeat;
  background-size: contain;
  width: 64px;
  height: 64px;
}

.icon--upload-checked {
  background: url(/public/assets/images/icons/upload-checked.svg) center center no-repeat;
  background-size: contain;
  width: 64px;
  height: 64px;
}

.icon--user-small {
  background: url(/public/assets/images/icons/user.svg) center center no-repeat;
  background-size: contain;
  width: 44px;
  height: 44px;
}

.icon--search {
  background: url(/public/assets/images/icons/search.svg) center center no-repeat;
  background-size: contain;
  width: 25px;
  height: 25px;
}

.icon--logout {
  background: url(/public/assets/images/icons/logout.svg) center center no-repeat;
  background-size: contain;
  width: 25px;
  height: 25px;
}

.icon--user-big {
  background: url(/public/assets/images/icons/user-big.svg) center center no-repeat;
  background-size: contain;
  width: 70px;
  height: 70px;
}

.icon--chat {
  background: url(/public/assets/images/icons/chat.svg) center center no-repeat;
  background-size: contain;
  width: 27px;
  height: 27px;
}

/* icon text */
.icon-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.icon-text i {
  margin-right: 8px;
}

/* colors */
.icon--success {
  color: #46BC7C;
}

/* -----

MEDIAQUERIES 

----- */
@media (max-width: 992px) {
  .row-end {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
/* ------------------------------------------------------------
     FORM
------------------------------------------------------------ */
.form-block {
  width: 100%;
}
.form-block form {
  width: 100%;
}

.form-row {
  margin-bottom: 21px;
  width: 100%;
}
.form-row:last-child {
  margin-bottom: 0;
}
.form-row.form--last {
  text-align: center;
}
.form-row.form--last .btn {
  width: 100%;
}
.form-row.last--button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: start;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.form-row.last--button .btn {
  width: auto;
  margin: 0 8px;
}

small.required {
  color: #1F7164;
}

.form-label {
  font-size: 16px;
}
.form-label.label--icon {
  text-wrap: balance;
}
.form-label.label--icon .icon {
  color: #1F7164;
  font-size: 21px;
  line-height: 25px;
  position: relative;
  top: 4px;
  margin-left: 4px;
}
.form-label.error {
  color: #DB443A;
}

.form-control,
.form-select {
  border-color: #91D6C4;
  border-radius: 12px;
  height: 56px;
}
.form-control:focus,
.form-select:focus {
  border-color: #91D6C4;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(84, 188, 188, 0.53), 0px 0px 5px 2px rgba(84, 188, 188, 0.28);
          box-shadow: 0px 0px 10px 0px rgba(84, 188, 188, 0.53), 0px 0px 5px 2px rgba(84, 188, 188, 0.28);
}
.form-control.form--green,
.form-select.form--green {
  border-color: #91D6C4;
}
.form-control.form--green:focus,
.form-select.form--green:focus {
  border-color: #2F6858;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(145, 214, 196, 0.44);
          box-shadow: 0 0 0 0.25rem rgba(145, 214, 196, 0.44);
}
.form-control.error,
.form-select.error {
  border-color: #DB443A;
}

textarea.form-control {
  height: 100px;
}

.form-password {
  position: relative;
}
.form-password .toggle-password {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 50px;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

/* password rules */
.password-rules {
  padding-top: 24px;
  color: #8A8A8A;
}
.password-rules ul li {
  color: #8A8A8A;
}

.password-strength {
  width: 100%;
  background: #CDCDCD;
  height: 15px;
  border-radius: 8px;
  position: relative;
}

.password-strength__bar {
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 8px;
  height: 100%;
}

.form-check.accordeon--checker {
  position: relative;
  border-radius: 12px;
  padding: 10px 10px 12px 10px;
  margin-bottom: 24px;
}
.form-check.accordeon--checker:last-child {
  margin-bottom: 0;
}
.form-check.accordeon--checker input,
.form-check.accordeon--checker label {
  position: relative;
  z-index: 1;
}
.form-check.accordeon--checker input {
  margin-left: 0;
}
.form-check.accordeon--checker label {
  padding-left: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-top: 2px;
}
.form-check.form-switch {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.form-check.form-switch input {
  width: 60px;
  height: 34px;
  margin-right: 16px;
  background-color: #B1B1B1;
  border: 0;
  --bs-form-switch-bg: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e);
}
.form-check.form-switch input:after {
  display: none;
}
.form-check.form-switch input:checked {
  background-color: #1F7164;
}
.form-check.check--flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.form-check.check--flex input {
  margin-top: 0;
  margin-right: 16px;
}

.form-check-input[type=checkbox] {
  width: 28px;
  height: 28px;
  border: 1px solid #1F7164;
  margin-top: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.form-check-input[type=checkbox]:after {
  content: "\f00c";
  font-weight: 900;
  font-family: "Font Awesome 6 Free";
  opacity: 0;
  padding-top: 2px;
}
.form-check-input[type=checkbox]:checked {
  background-color: #fff;
  border-color: #6BBBAE;
  color: #1F7164;
}
.form-check-input[type=checkbox]:checked + .form-check-input__back {
  background: #CDF8E5;
}
.form-check-input[type=checkbox]:checked:after {
  opacity: 1;
}
.form-check-input[type=checkbox].checkbox--green {
  border-color: #91D6C4;
}
.form-check-input[type=checkbox].checkbox--green:checked {
  color: #2F6858;
}
.form-check-input[type=checkbox].checkbox--green:focus {
  border-color: #2F6858;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(84, 188, 188, 0.53), 0px 0px 5px 2px rgba(84, 188, 188, 0.28);
          box-shadow: 0px 0px 10px 0px rgba(84, 188, 188, 0.53), 0px 0px 5px 2px rgba(84, 188, 188, 0.28);
}

.accordeon-number {
  padding-right: 16px;
}

.form-check-input__back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #F0FFF8;
  border-radius: 12px;
}

.form-check-input {
  cursor: pointer;
}
.form-check-input:checked {
  background-color: #1F7164;
  border-color: #1F7164;
}
.form-check-input:focus {
  border-color: #16615C;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(84, 188, 188, 0.53), 0px 0px 5px 2px rgba(84, 188, 188, 0.28);
          box-shadow: 0px 0px 10px 0px rgba(84, 188, 188, 0.53), 0px 0px 5px 2px rgba(84, 188, 188, 0.28);
}

/* phone */
.react-international-phone-input-container {
  width: 100%;
}
.react-international-phone-input-container input[type=tel] {
  width: 100%;
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid #1F7164;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  border-radius: 12px;
  height: 56px;
  margin-left: 8px;
}
.react-international-phone-input-container input[type=tel]:focus {
  border-color: #1F7164;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(84, 188, 188, 0.53), 0px 0px 5px 2px rgba(84, 188, 188, 0.28);
          box-shadow: 0px 0px 10px 0px rgba(84, 188, 188, 0.53), 0px 0px 5px 2px rgba(84, 188, 188, 0.28);
}
.react-international-phone-input-container button.react-international-phone-country-selector-button {
  border: var(--bs-border-width) solid #1F7164;
  border-radius: 12px;
  height: 56px;
}
.react-international-phone-input-container button.react-international-phone-country-selector-button .react-international-phone-country-selector-button__dropdown-arrow {
  background: url(/public/assets/images/icons/arrow-down.svg) center center no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
  border: 0;
  margin-left: 8px;
}

/* verification code */
.vi__container {
  padding: 0;
  max-width: 400px;
  margin: 0;
}
.vi__container .vi__character {
  width: 100%;
  display: block;
  width: 100%;
  padding: 0 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #1F7164;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: 2px solid #1F7164;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  border-radius: 12px;
  height: 64px;
  line-height: 56px;
  margin-left: 0;
  font-size: 28px;
}
.vi__container .vi__character.character--selected {
  border-color: #1F7164;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(84, 188, 188, 0.53), 0px 0px 5px 2px rgba(84, 188, 188, 0.28);
          box-shadow: 0px 0px 10px 0px rgba(84, 188, 188, 0.53), 0px 0px 5px 2px rgba(84, 188, 188, 0.28);
  outline: initial;
}
.vi__container .vi__character:not(.character--inactive) {
  border-color: #529770;
}

/* form multi */
.form-check-multi {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.form-check-multi .form-check {
  margin-right: 32px;
}
.form-check-multi .form-check:last-child {
  margin-right: 0;
}

/* row check 2 */
.row-check-2.row--check-top .form-check {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.row-check-2 .form-check {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.row-check-2 .form-check label {
  padding-left: 16px;
}

.row-check-2__col {
  margin-bottom: 24px;
  padding-right: 0;
}

/* Tag search */
.ReactTags__tags .ReactTags__selected {
  margin-bottom: 8px;
}
.ReactTags__tags .ReactTags__selected .tag-wrapper.ReactTags__tag {
  border-radius: 90px;
  padding: 6px 10px;
  background: #CDF8E5;
  border: 1px solid #529770;
  display: inline-block;
  margin-right: 4px;
  margin-bottom: 4px;
  font-size: 12px;
  color: #529770;
  font-weight: 700;
  text-transform: uppercase;
}
.ReactTags__tags .ReactTags__selected .tag-wrapper.ReactTags__tag .ReactTags__remove {
  font-size: 18px;
  color: #529770;
}

.ReactTags__tagInput {
  margin-bottom: 32px;
}
.ReactTags__tagInput .ReactTags__tagInputField {
  border: 1px solid #91D6C4;
  border-radius: 12px;
  height: 56px;
  width: 100%;
  background-color: #fff;
  background-image: url(/public/assets/images/icons/search-big.svg);
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: 16px 18px;
  padding-left: 48px;
}
.ReactTags__tagInput .ReactTags__tagInputField:focus {
  border-color: #91D6C4;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(84, 188, 188, 0.53), 0px 0px 5px 2px rgba(84, 188, 188, 0.28);
          box-shadow: 0px 0px 10px 0px rgba(84, 188, 188, 0.53), 0px 0px 5px 2px rgba(84, 188, 188, 0.28);
}
.ReactTags__tagInput .ReactTags__suggestions {
  position: absolute;
  background: #F6F6F6;
  border-radius: 12px;
  width: 100%;
  z-index: 999;
  -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  margin-top: 4px;
}
.ReactTags__tagInput .ReactTags__suggestions ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.ReactTags__tagInput .ReactTags__suggestions ul li {
  padding: 5px 10px;
  font-size: 13px;
  border-radius: 12px;
  cursor: pointer;
}
.ReactTags__tagInput .ReactTags__suggestions ul li span mark {
  background: #91D6C4;
  border-radius: 12px;
}
.ReactTags__tagInput .ReactTags__suggestions .ReactTags__activeSuggestion {
  background: #CDF8E5;
  color: #529770;
}
.ReactTags__tagInput .ReactTags__suggestions .ReactTags__suggestion {
  padding: 12px 10px;
  font-size: 13px;
  color: #1f1f1f;
}
.ReactTags__tagInput .ReactTags__suggestions .ReactTags__suggestion:hover {
  background: #91D6C4;
  color: #6BBBAE;
}

.form-check-label.label--icon .icon {
  margin-left: 8px;
  font-size: 21px;
  line-height: 25px;
  color: #1F7164;
  position: relative;
  top: 4px;
}

/* -----

MEDIAQUERIES 

----- */
@media (max-width: 768px) {
  .form-row__col-small {
    margin-bottom: 21px;
  }
  .form-big-row {
    padding-bottom: 24px;
    margin-bottom: 24px;
  }
  .form-big-row.big-row--last .btn {
    margin: 0 8px 24px 8px;
    width: 100%;
  }
  .form-row.last--button {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .form-row.last--button .btn {
    width: 100%;
    margin: 0 auto 16px auto;
  }
}
/* ------------------------------------------------------------
     ALERTS
------------------------------------------------------------ */
.alert {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 10px;
}
.alert.alert-danger {
  background: transparent;
  border: 0;
  color: #DB443A;
  padding: 0;
}
.alert.alert-danger.alert--small {
  padding: 0;
  margin-top: 4px !important;
}
.alert.alert--complete {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.alert.alert--complete > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.alert.alert-success {
  background: #A9E5B7;
  border-color: #2F6858;
  color: #2F6858;
}
.alert.alert-success a {
  color: #2F6858;
  font-weight: 700;
}
.alert.alert--small {
  padding: 5px 10px;
  font-size: 14px;
}
.alert.alert--soft-success {
  background: #F0FFF8;
  border-color: #6BBBAE;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.alert.alert--soft-success .icon {
  position: relative;
  top: 4px;
}
.alert.alert--soft-danger {
  background: #FFEBEB;
  border-color: #C47A90;
  color: #AC0B1F;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.alert.alert--soft-danger .icon {
  position: relative;
  top: 4px;
}
.alert.alert--simple-check {
  color: #46BC7C;
  padding-left: 0;
  padding-right: 0;
}
.alert.alert--simple-check .icon {
  font-size: 40px;
  margin-right: 16px;
}
.alert.alert--icon {
  padding: 16px;
}
.alert article:last-child {
  padding-left: 16px;
}
.alert p {
  margin-bottom: 0;
}
.alert a {
  font-weight: 700;
}
.alert ul {
  margin-bottom: 0;
}

/* header alert */
.header-alert {
  border: 2px solid #AC0B1F;
  color: #AC0B1F;
  background: #fff;
  border-radius: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 18px 16px;
  min-width: 300px;
}
.header-alert p {
  margin-bottom: 0;
  font-weight: 600;
  letter-spacing: -0.5px;
}
.header-alert .icon {
  margin-right: 16px;
}

/* -----

 MEDIAQUERIES 

----- */
/* ------------------------------------------------------------
     TOOLTIP
------------------------------------------------------------ */
.tooltip-inner {
  background-color: #CDF8E5;
  border: 2px solid #6BBBAE;
  border-radius: 12px 12px 12px 0;
  color: #1f1f1f;
  padding: 20px;
  font-weight: 500;
  font-family: "CircularStd-Book", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  max-width: 300px;
  width: inherit;
}

.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before,
.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: #6BBBAE;
}

/* -----

 MEDIAQUERIES 

----- */
/* ------------------------------------------------------------
     LOADER
------------------------------------------------------------ */
.loader-wrapp {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: #CDF8E5;
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #1F7164;
  border-bottom-color: #6BBBAE;
  border-radius: 50%;
  display: inline-block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-animation: rotation 1s linear infinite;
          animation: rotation 1s linear infinite;
}

@-webkit-keyframes rotation {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes rotation {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
/* -----

 MEDIAQUERIES 

----- */
/* ------------------------------------------------------------
     MODAL
------------------------------------------------------------ */
.modal-header {
  border: 0;
}
.modal-header p {
  font-family: "CircularStd-Book", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 400;
}

.modal-content {
  border-radius: 16px;
  padding: 20px;
}

.modal-footer {
  border: 0;
}

/* -----

 MEDIAQUERIES 

----- */
@media (max-width: 1200px) {
  .modal-card__col {
    margin-bottom: 24px;
  }
}
@media (max-width: 768px) {
  .modal-content {
    padding: 0;
  }
  .modal-selection__left {
    margin-bottom: 24px;
  }
}
.stepper {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.stepper p {
  margin: 0;
  padding-left: 8px;
}

.stepper__main {
  width: 100%;
  background: #CDCDCD;
  border-radius: 8px;
  position: relative;
  height: 14px;
}

.stepper__item {
  position: absolute;
  height: 100%;
  border-radius: 8px;
  left: 0;
  top: 0;
  background: #1F7164;
}

/* -----

 MEDIAQUERIES 

----- */
/* ------------------------------------------------------------
     CAUSAL CARD
------------------------------------------------------------ */
.causal-card {
  padding: 20px;
  border-radius: 12px;
  background: #fff;
  border: 2px solid #C47A90;
}
.causal-card.card--color1 {
  border-color: #6BBBAE;
}
.causal-card.card--color1 h3 {
  color: #144B59;
}
.causal-card.card--color2 {
  border-color: #9B7AC4;
}
.causal-card.card--color2 h3 {
  color: #9B7AC4;
}
.causal-card h3 {
  color: #C47A90;
}

.card-accordeon {
  border: 0;
}
.card-accordeon .accordion-item {
  border: 0;
}
.card-accordeon .accordion-item .accordion-header .accordion-button {
  font-size: 24px;
  line-height: 35px;
  font-family: "CircularStd-Book", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 600;
  background: transparent;
  border: 0;
  color: #144B59;
  -webkit-box-shadow: initial;
          box-shadow: initial;
  padding-left: 0;
  padding-right: 0;
}
.card-accordeon .accordion-item .accordion-header .accordion-button:after {
  content: "\f078";
  font-weight: 900;
  font-family: "Font Awesome 6 Free";
  border: 0;
  height: auto;
  width: auto;
  background: transparent;
}
.card-accordeon .accordion-item .accordion-header .accordion-button[aria-expanded=true]:after {
  color: #1F7164;
}
.card-accordeon .accordion-item .accordion-body {
  padding-left: 0;
  padding-right: 0;
}

.accordeon-text {
  margin-bottom: 5px;
}

/* -----

 MEDIAQUERIES 

----- */
/* ------------------------------------------------------------
     DROPZONE
------------------------------------------------------------ */
.dropzone {
  padding: 16px;
  border: 1px dashed #1F7164;
  border-radius: 8px;
  text-align: center;
}

.dropzone__files {
  margin-top: 24px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.dropzone__files ul {
  list-style: none;
  padding: 16px 0 0 0;
  margin: 0;
  width: 100%;
}
.dropzone__files ul li {
  margin-bottom: 0;
  font-size: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

/* -----

 MEDIAQUERIES 

----- */
/* ------------------------------------------------------------
     VIOLENTOMETRO
------------------------------------------------------------ */
.violent-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 19px;
  height: 52px;
}
.violent-row:last-child {
  margin-bottom: 0;
}
.violent-row .form-check-input[type=checkbox] {
  border-color: #91D6C4;
}
.violent-row .form-check-input[type=checkbox]:checked {
  color: #2F6858;
}
.violent-row .form-check-input[type=checkbox]:focus {
  border-color: #2F6858;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(145, 214, 196, 0.44);
          box-shadow: 0 0 0 0.25rem rgba(145, 214, 196, 0.44);
}
.violent-row .form-check {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  width: 440px;
}
.violent-row .form-check.check--center label {
  padding-top: 6px;
}
.violent-row .form-check label {
  padding-left: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  height: 52px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.violent-row .form-check label * {
  width: 100%;
}
.violent-row .form-check label small {
  display: block;
  font-size: 12px;
  letter-spacing: -0.3px;
}

.violent-number {
  color: #1f1f1f;
  margin-right: 40px;
  padding-right: 30px;
  text-align: right;
  position: relative;
  margin-bottom: 0;
  width: 50px;
  height: 24px;
  top: 5px;
}
.violent-number:after {
  content: "";
  width: 30px;
  height: 1px;
  background: #1f1f1f;
  display: block;
  right: -14px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.violent-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.violent-figure {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  width: 44px;
  margin-right: 16px;
  margin-left: 30px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-bottom: 0;
  position: relative;
}
.violent-figure:after {
  content: "";
  background: #EDEDED;
  width: 28px;
  border-radius: 30px;
  height: 100%;
  position: absolute;
  bottom: 12px;
  left: 50%;
  z-index: 1;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.violent-figure__end {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background: #7500BD;
  border: 10px solid #EDEDED;
  position: relative;
  top: -12px;
  z-index: 2;
}

.violent-level:first-child .violent-figure__item:first-child {
  border-radius: 30px 30px 0 0;
  height: 100px;
}
.violent-level.level1 p {
  top: 100%;
}

.violent-figure__item {
  width: 14px;
  height: 70px;
  position: relative;
  z-index: 3;
}
.violent-figure__item.item--color4 {
  background: #7500BD;
}
.violent-figure__item.item--color3 {
  background: #DC0303;
}
.violent-figure__item.item--color2 {
  background: #FFAB48;
}
.violent-figure__item.item--color1 {
  background: #FFE248;
}

.violent-level {
  width: 14px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.violent-level p {
  position: absolute;
  top: 50%;
  left: -30px;
  -webkit-transform: translateX(-50%) rotate(270deg);
          transform: translateX(-50%) rotate(270deg);
  margin: 0;
  white-space: nowrap;
}

/* -----

 MEDIAQUERIES 

----- */
@media (max-width: 768px) {
  .violent-number {
    display: none;
  }
  .violent-row .form-check-label {
    font-size: 14px;
    line-height: 14px;
  }
  .violent-row .form-check-label small {
    font-size: 11px;
    line-height: 18px;
  }
  .violent-row .form-check {
    width: 290px;
  }
}
/* ------------------------------------------------------------
     STATUS BAR
------------------------------------------------------------ */
.status-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  padding: 32px 0;
}

.status-item {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  padding-right: 16px;
}
.status-item.status--success .status-item__top hr {
  background-color: #529770;
}
.status-item.status--success .status-item__icon {
  background: #529770;
}
.status-item.status--process .status-item__icon {
  background: #C47A90;
  border: 5px solid #fff;
  outline: 2px solid #C47A90;
}

.status-item__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 24px;
}
.status-item__top hr {
  width: 100%;
  height: 3px;
  background-color: #8A8A8A;
  border: 0;
  opacity: 1;
  margin: 0;
  width: 90%;
}

.status-item__icon {
  width: 23px;
  height: 22px;
  border-radius: 50%;
  background: #8A8A8A;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-right: 8px;
}
.status-item__icon i {
  color: #fff;
}

.status-item__bottom p {
  margin: 0;
  max-width: 160px;
}

/* status bar mobile */
.status-bar__mobile {
  display: none;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.status-bar__circle {
  width: 70px;
  height: 70px;
  position: relative;
  margin-right: 24px;
}

.status-bar__step {
  background: #1F7164;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-weight: bold;
  letter-spacing: -0.5px;
  color: #fff;
  border-radius: 50%;
}

/* -----

 MEDIAQUERIES 

----- */
@media (max-width: 992px) {
  .status-bar {
    display: none;
  }
  .status-bar__mobile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 40px 0;
  }
}
@media (max-width: 680px) {
  .status-bar__step {
    font-size: 16px;
  }
}
/* ------------------------------------------------------------
     CHATBOX
------------------------------------------------------------ */
.list-checker {
  list-style: none;
  padding: 0;
  margin: 0;
}
.list-checker li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 32px;
}
.list-checker li:last-child {
  margin-bottom: 0;
}
.list-checker li article {
  padding-left: 16px;
}
.list-checker li article p {
  margin: 0;
}

.chatbox-info {
  background: #F6F6F6;
  padding: 40px 32px;
  border-radius: 8px;
  border: 1px solid #6BBBAE;
}

.chatbox-info__row {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.chatbox-action {
  background: #fff;
  padding: 32px;
  border-radius: 16px;
}

.chatbox-action__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 32px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.chatbox-action__user {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 8px;
}
.chatbox-action__user figure {
  margin: 0;
  width: 77px;
  height: 77px;
}
.chatbox-action__user figure img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center;
     object-position: center;
}
.chatbox-action__user article {
  padding-left: 16px;
}
.chatbox-action__user article p,
.chatbox-action__user article h4 {
  margin: 0;
}

/* placeholder */
.chatbox-placeholder {
  width: 100%;
  background: #FFEFFF url(/public/assets/images/background/pattern.png) center center repeat;
  background-size: 300px;
  height: 758px;
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid #845DB6;
  position: relative;
  padding: 50px 24px 70px 24px;
}

.chat-container .rce-container-mlist {
  height: 654px;
}
.chat-container .rce-mlist {
  overflow-y: scroll;
}
.chat-container .rce-mbox {
  min-width: 320px;
}

.chatbox__message {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}
.chatbox__message figure {
  width: 40px;
  height: 40px;
  margin: 0;
}
.chatbox__message figure img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  overflow: hidden;
}
.chatbox__message article {
  padding: 16px;
  background: #F1BCEF;
  border-radius: 10px 10px 10px 0;
  margin-left: 16px;
  max-width: 400px;
  width: 80%;
}
.chatbox__message article,
.chatbox__message article * {
  margin-bottom: 0;
}

.chatbox__row {
  width: 100%;
  margin-top: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  padding: 0 15px;
}
.chatbox__row.row--right {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.chatbox__row.row--right .chatbox__message {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.chatbox__row.row--right .chatbox__message figure {
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
}
.chatbox__row.row--right .chatbox__message article {
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
  margin-left: 0;
  margin-right: 16px;
  background: #845DB6;
  border-radius: 10px 10px 0 10px;
}
.chatbox__row.row--right .chatbox__message article,
.chatbox__row.row--right .chatbox__message article * {
  color: #fff;
}

.chatbox__send {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}
.chatbox__send .rce-container-input {
  border-radius: 0 0 20px 20px;
}

/* -----

 MEDIAQUERIES 

----- */
@media (max-width: 1200px) {
  .chatbox-action__user {
    margin-bottom: 16px;
  }
}
@media (max-width: 992px) {
  .chatbox-info__text {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  .chatbox-info__module {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
    margin-bottom: 24px;
  }
}
@media (max-width: 768px) {
  .chatbox-action__top {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .chatbox-info {
    padding: 40px 22px;
  }
}
/* ------------------------------------------------------------
     HOSPITAL
------------------------------------------------------------ */
.hospital-card__text .material-icons-outlined {
  font-size: 18px;
  margin-right: 8px;
}

.hospital-card {
  padding: 16px 22px;
  border: 1px solid #91D6C4;
  border-radius: 8px;
}
.hospital-card p {
  margin-bottom: 0;
}
.hospital-card p.tag {
  margin: 8px 0;
}

.hospital-card__end {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

/* map side */
.map-side {
  position: sticky;
  top: 0;
}
.map-side #map {
  height: 100% !important;
  min-height: 500px;
}

.hospital-wrapp__search {
  margin-bottom: 40px;
}

.map-side .btn--close {
  display: none;
}

.hospital-wrapp__show {
  display: none;
}

.map-small {
  overflow: hidden;
  border-radius: 8px;
  margin-bottom: 32px;
}

/* -----

 MEDIAQUERIES 

----- */
@media (max-width: 992px) {
  .hospital-wrapp__search {
    margin-bottom: 16px;
  }
  .map-side {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background: rgba(255, 255, 255, 0.7);
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    padding: 30px;
    opacity: 0;
    pointer-events: none;
    -webkit-transition: 0.4s all ease-in-out;
    transition: 0.4s all ease-in-out;
  }
  .map-side.active {
    opacity: 1;
    pointer-events: all;
  }
  .map-side .btn--close {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 10;
    display: block;
  }
  .hospital-wrapp__show {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .hospital-card__end {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    margin-top: 32px;
  }
  .hospital-wrapp > .row {
    margin: 0;
  }
  .hospital-wrapp > .row > div {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (max-width: 768px) {
  .hospital-card__end .btn {
    width: 100%;
  }
}
/* ------------------------------------------------------------
     TAGS
------------------------------------------------------------ */
.tag {
  text-transform: uppercase;
  font-family: "CircularStd-Black", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 12px;
  border: 1px solid #8A8A8A;
  border-radius: 90px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 6px 10px;
  background: #EDEDED;
}
.tag.tag--success {
  background: #A0DAB3;
  border-color: #144B59;
  color: #144B59;
}
.tag.tag--success .material-icons-outlined {
  color: #144B59;
}
.tag.tag--error {
  background: #E4A9BB;
  border-color: #8D3D64;
  color: #8D3D64;
}
.tag.tag--error .material-icons-outlined {
  color: #8D3D64;
}
.tag .material-icons-outlined {
  color: #707070;
  margin-right: 8px;
  font-size: 17px;
}
.tag.tag--warning {
  background: #F2E9C5;
  border-color: #8D633D;
  color: #8D633D;
}
.tag.tag--warning .material-icons-outlined {
  color: #8D633D;
}

/* -----

 MEDIAQUERIES 

----- */
/* ------------------------------------------------------------
     RATING
------------------------------------------------------------ */
/* rating */
.rating-form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  max-width: 240px;
  width: 100%;
}
.rating-form svg {
  height: 2em;
}

.rating-card {
  padding: 32px;
  border: 1px solid #E4A9BB;
  border-radius: 16px;
}

/* -----

 MEDIAQUERIES 

----- */
/* ------------------------------------------------------------
     AUTH
------------------------------------------------------------ */
.auto-row__right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

/* active code */
.active-choose {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 60px;
  margin-bottom: 60px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
}
.active-choose button {
  margin-right: 30px;
}
.active-choose button:last-child {
  margin-right: 0;
}

/* -----

 MEDIAQUERIES 

----- */
@media (max-width: 768px) {
  .auto-row__right {
    display: none;
  }
  .active-choose {
    margin-top: 30px;
    margin-bottom: 20px;
  }
}
/* ------------------------------------------------------------
    LARGE
------------------------------------------------------------ */
/* ------------------------------------------------------------
    MEDIUM
------------------------------------------------------------ */
@media (max-width: 992px) {
  .hide-tablet {
    display: none !important;
  }
}
@media (max-width: 768px) {
  h1,
  .h1 {
    font-size: 32px;
    line-height: 45px;
  }
  h2,
  .h2 {
    font-size: 24px;
    line-height: 35px;
  }
  h3,
  .h3 {
    font-size: 21px;
    line-height: 35px;
  }
  .module {
    padding: 40px 0;
  }
  .module-bottom {
    padding-bottom: 40px;
  }
  .module-top {
    padding-top: 40px;
  }
  .module40 {
    padding: 30px 0;
  }
  .module-bottom40 {
    padding-bottom: 30px;
  }
  .module-top40 {
    padding-top: 30px;
  }
  .text-24,
  .text-24 *,
  .text-20,
  .text-20 * {
    font-size: 18px;
    line-height: 25px;
  }
  .text-32,
  .text-32 *,
  .text-40,
  .text-40 * {
    font-size: 24px;
    line-height: 35px;
  }
  .text-64,
  .text-64 * {
    font-size: 40px;
    line-height: 45px;
  }
  .text-52,
  .text-52 * {
    font-size: 32px;
    line-height: 40px;
  }
}
/* ------------------------------------------------------------
    SMALL
------------------------------------------------------------ */
@media (max-width: 680px) {
  .body-row {
    margin: 0;
  }
  .module {
    padding: 30px 0;
  }
  .module-bottom {
    padding-bottom: 30px;
  }
  .module-top {
    padding-top: 30px;
  }
  .module40 {
    padding: 20px 0;
  }
  .module-bottom40 {
    padding-bottom: 20px;
  }
  .module-top40 {
    padding-top: 20px;
  }
  .text-20,
  .text-20 * {
    font-size: 16px;
    line-height: 25px;
  }
  .text-32,
  .text-32 *,
  .text-40,
  .text-40 * {
    font-size: 20px;
    line-height: 30px;
  }
  .text-64,
  .text-64 * {
    font-size: 32px;
    line-height: 40px;
  }
}